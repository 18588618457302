import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { NavLink } from 'react-router-dom'
import Logo from '../Logo'

import Typography from '@material-ui/core/Typography'

import MenuIcon from '@material-ui/icons/Menu'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'

import { User } from '../../store/interfaces'

const drawerWidth = 280

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  banner: {
    minHeight: 24,
    fontSize: '0.85rem',
    fontWeight: 'bold',
    backgroundColor: '#2a2a2a',
    justifyContent: 'center',
    color: '#fff',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width',], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width',], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}))

export type DrawerProps = {
  currentUser?: null | User
  children?: React.ReactNode
}

const StyledSection = styled.section`
  height: 100vh;
  overflow-y: scroll;
`

const StyledAppBar = styled(AppBar)`
  a {
    /* height: 100%; */
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  img[alt="LarCity Logo"] {
    height: 2rem; width: auto;
  }
`

export default function DrawerComponent({ children, }: DrawerProps): JSX.Element {
  const [isOpen, setIsOpen,] = React.useState<boolean>()
  const classes = useStyles()

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar variant="dense" className={clsx(classes.banner)}>
          LarCity LLC is a Black-owned business
        </Toolbar>
        <Toolbar role="navigation">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              // setIsOpen(true)
            }}
            className={clsx(classes.menuButton /*, isOpen && classes.hide*/)}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to="/">
            <Logo />
            <Typography variant="h6" noWrap>&nbsp; LarCity LLC</Typography>
          </NavLink>
          {/* <Typography variant="h6" noWrap>
            Persistent drawer
          </Typography> */}
        </Toolbar>
      </StyledAppBar>

      <StyledSection>
        {children}
      </StyledSection>

      <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor="left">
        <div
          className={clsx(classes.list, {
            [classes.fullList]: true,
          })}
          role="presentation">
          <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts',].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['All mail', 'Trash', 'Spam',].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  )
}