import { createMuiTheme } from '@material-ui/core/styles'

// Color customizations: https://material-ui.com/customization/palette/#providing-the-colors-directly
export default createMuiTheme({
  palette: {
    primary: {
      main: '#fee020',
      dark: '#ffb300',
      light: '#ffff5f',
    },
    secondary: {
      main: '#09814a',
      dark: '#2a2a2a',
    },
  },
})
