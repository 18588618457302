import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../theme'
import Drawer from '../components/Drawer'
// @Todo get TS paths (src/*, component/*) working in Storybook
import { Container } from '../components'

const StyledContainer = styled(Container)`
  min-height: 100vh;
`

type Props = {
  children?: React.ReactNode
}

export default function PageLayout({ children, }: Props): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Drawer>
        <StyledContainer>
          {children}
        </StyledContainer>
      </Drawer>
    </ThemeProvider>
  )
}