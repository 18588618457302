import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Logo = styled.img`
  height: 100%;
  width: auto;
`

const Banner = styled.div`
  background-color: #2a2a2a;
  color: #fff;
  width: 100vw;
`

const Nav = styled.nav`
  color: #2a2a2a;
  height: 2rem;
`

const NavContainer = styled.nav`
  position: fixed;
  z-index: 2;
  width: 100vw;
  top: 0; 
  left: 0;
  padding: 0.5rem;
  background-color: #e8e8e8;

  nav {
    margin: 0 auto;
    
    @media screen and (min-width: 640px) {
      max-width: 80vw;
      margin: 0 auto;
    }

    a {
      height: 100%;
      border: none;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
`

export default function Topbar(): JSX.Element {
  return (
    <NavContainer>
      <Banner />
      <Nav>
        <NavLink to="/">
          <Logo src="/android-chrome-192x192.png" alt="LarCity Logo" />
          <span>&nbsp; LarCity LLC</span>
        </NavLink>

      </Nav>
    </NavContainer>
  )
}
