import { copyrightStatement } from '../utils'

export default function Footer(): JSX.Element {
  return (
    /** <!-- Footer --> **/
    <footer id="footer">
      <span className="copyright">
        {copyrightStatement()}
      </span>
      <div>
        <a href="/terms/privacy-policy" target="_blank">Privacy Policy</a>
        &nbsp; | &nbsp;
        <strong>Design</strong>&nbsp;
        <a href="http://html5up.net" rel="noopener noreferrer" target="_blank">HTML5 UP</a>.
      </div>
    </footer>
  )
}
