import styled from 'styled-components'

const LogoImage = styled.img`
  display: inline-block;
  width: auto;
`

export default function Logo(): JSX.Element {
  return (
    <LogoImage src="/android-chrome-192x192.png" alt="LarCity Logo" />
  )
}

