import React from 'react'
import { Footer } from '../../components'

import '../../styles/welcome.scss'

import 'bootstrap/dist/js/bootstrap.js'

export default function Welcome(): JSX.Element {
  const body = document.getElementsByTagName<'body'>('body')[0]

  window.onload = function() {
    body.classList.remove('is-preload')
  }

  window.ontouchmove = function() {
    return false
  }

  window.ondeviceorientation = function() {
    document.body.scrollTop = 0
  }

  React.useEffect(() => {
    // $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
    // $('[data-toggle="popover"]').popover({
    //   boundary: 'window',
    //   trigger: 'hover focus',
    //   template: PopoverTemplate,
    //   delay: { show: 0, hide: 100 }
    // });

    body.classList.remove('is-preload')
  }, [body.classList,])

  return (
    <div id="wrapper">
      <div id="bg"></div>
      <div id="overlay"></div>
      <div id="main">
        {/* <!-- Header --> */}
        <header id="header">
          <h1>Made By LarCity</h1>
          <p>Tools for startups. Proof of concepts.</p>
          <nav>
            <ul>
              {/* <li><a href="/sign-up" className="icon fa-sign-in"><span className="label">Join</span></a></li> */}
              <li>
                <a
href="https://uptime.lar.city/"
               className="icon fa-signal"
               data-toggle="popover"
               data-trigger="hover focus"
               data-placement="bottom"
               title="Is this thing on?!"
               data-html="true"
               data-content="Check the availability of our Services, Apps & Tools">
                  <span className="label">Status</span></a>
              </li>
              <li>
                <a
href="https://uchechilaka.blog"
               className="icon fa-wordpress"
               data-toggle="tooltip"
               data-placement="top"
               title="Owner's Blog"><span className="label">Blog</span></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/larcityapps"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon fa-twitter"
                  data-toggle="popover"
                  data-trigger="hover focus"
                  data-html="true"
                  data-placement="bottom"
                  title="We're @LarcityApps"
                  data-content="Follow us on Twitter for releases and other announcements!">
                  <span className="label">Twitter</span>
                </a>
              </li>
              {/* <li><a href="https://www.facebook.com/larcitylab"
                  target="_blank"
                  className="icon fa-facebook"><span className="label">Facebook</span></a></li> */}
              {/* <li><a href="#"
                  className="icon fa-github"><span className="label">Github</span></a></li> */}
              <li>
                <a
                  href="https://gitlab.com/madebylar.city"
                  className="icon fa-gitlab"
                  data-toggle="popover"
                  data-trigger="hover focus"
                  data-html="true"
                  data-placement="bottom"
                  title="We git @ GitLab"
                  data-content="We've got a few projects on the way, but nothing open source yet - building stuff is hard! 🤦🏽">
                  <span className="label">GitLab</span></a>
              </li>
              {/* <!-- https://gitlab.com/madebylar.city -->
          <!-- https://bitbucket.org/larcitylabteam/ --> */}
              {/* <li><a href="https://bitbucket.org/larcitylabteam/"
                  target="_blank"
                  className="icon fa-bitbucket"><span className="label">BitBucket</span></a></li> */}
              <li>
                <a
                  href="mailto:support@lar.city?subject=I've got a question"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="icon fa-envelope-o"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Email us">
                  <span className="label">Inbox @LarCity</span>
                </a>
              </li>
            </ul>
          </nav>

          {/* <div>
        <button className="btn btn-outline-secondary"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Test popup">
          Something here
        </button>
      </div> */}
        </header>

        {/* <!-- Footer Component --> */}
        <Footer />
      </div>
    </div>
  )
}