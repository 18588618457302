import React from 'react'
import styled from 'styled-components'

import Welcome from './Welcome'

const StyleVideo = styled.video`
  position: fixed;
  bottom: 0;
  z-index: 0;
  min-width: 100vw;
  min-height: 100vh;
`

const MainView = styled.main`
  position: absolute;
  top: 0;
  min-height: 100vh;
  z-index: 1;
  color: #fff;
`

const VIDEO_ID = 'small-biz-b-roll'

export default function Default(): JSX.Element {
  // @Todo center home video on window load / resize

  const [videoSrc,] = React.useState(() => ({
    '540px': '//storage.googleapis.com/com-larcity-assets/videos/SmallBizBRoll/960x540.mp4',
  }))

  return (
    <>
      <StyleVideo autoPlay muted loop id={VIDEO_ID}>
        <source src={videoSrc['540px']} type="video/mp4" />
      </StyleVideo>

      <MainView>
        <Welcome />
      </MainView>
    </>
  )
}
