import styled from 'styled-components'

import './mailchimp-horizontal.scss'

const StyledDiv = styled.div`
  #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; width:100%;}
  /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
`

export default function CompactSubscribeForm(): JSX.Element {
  return (
    <StyledDiv id="mc_embed_signup">
      <form
        action="https://larcity.us9.list-manage.com/subscribe/post?u=090999d08f150544df0c671d5&amp;id=acee899ae1"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate>
        <div id="mc_embed_signup_scroll">
          <label htmlFor="mce-EMAIL">Subscribe to the Going Pro Online Newsletter</label>
          <input
            type="email"
            value=""
            name="EMAIL"
            className="email"
            id="mce-EMAIL"
            placeholder="email address"
            required />
          <input type="hidden" name="SEGMENT" value="Email Services" />
          {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
          <div style={{ position: 'absolute', left: '-5000px', }} aria-hidden="true">
            <input type="text" name="b_090999d08f150544df0c671d5_acee899ae1" tabIndex={-1} value="" />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button" />
          </div>
        </div>
      </form>
    </StyledDiv>
  )
}