import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  color: #2a2a2a;
  margin: 0 auto;
  padding: 6rem 1rem 2rem;

  /* @Todo support app breakpoints via shared lib */
  /* @media screen and (max-width: 639px) {
    padding: 6rem 1rem 2rem;
  } */
  
  @media screen and (min-width: 640px) {
    max-width: 80vw;
    margin: 0 auto;
  }
`

type Props = {
  children?: React.ReactNode
}

export default function Container({ children, }: Props): JSX.Element {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}